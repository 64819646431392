// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rHRZpeOgbYxm9wagFT5G {
  height: 100dvh;
}
.rHRZpeOgbYxm9wagFT5G > div {
  width: max-content;
  margin: auto;
  background: unset;
  border: unset;
  height: 100%;
  display: flex;
  align-items: center;
}
.rHRZpeOgbYxm9wagFT5G > div > div {
  width: max-content;
  background: none;
  border: unset;
}`, "",{"version":3,"sources":["webpack://./src/shared/tg-main-loader/tg-main-loader.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AACI;EACI,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACR;AACQ;EACI,kBAAA;EACA,gBAAA;EACA,aAAA;AACZ","sourcesContent":[".loader-container {\n    height: 100dvh;\n\n    >div {\n        width: max-content;\n        margin: auto;\n        background: unset;\n        border: unset;\n        height: 100%;\n        display: flex;\n        align-items: center;\n\n        >div {\n            width: max-content;\n            background: none;\n            border: unset;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-container": `rHRZpeOgbYxm9wagFT5G`
};
export default ___CSS_LOADER_EXPORT___;
